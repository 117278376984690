import React, { Component } from 'react';
import { Image,  Col, Modal } from 'react-bootstrap';
import { ScaleLoader } from 'react-spinners';
//import ComponentWithDimensions from '../helpers/ComponentWithDimensions'
import ImageCanvas from '../helpers/ImageCanvas'


export class BasePhoto extends Component{
	constructor(props) {
		super(props);

		this.state = {
		  showModal: false,
		  currentPhoto: '',
		  showModal2: false,
		  currentText: "",
		  showModalObject: false,
		  currentObject: "",
		  showModalWebEntity: false,
		  currentWebEntity: "",
		  currentCanvasVertices: null,
		  areNormalizedVertices: false,
		};   

		this.handleClose = this.handleClose.bind(this);
		this.handleClose2 = this.handleClose2.bind(this);
		this.handleCloseObject = this.handleCloseObject.bind(this);
		this.handleCloseWebEntity = this.handleCloseWebEntity.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

    componentDidMount() {
      	console.log('BasePhoto: DidMOUNT init')
      	console.log('BasePhoto: DidMOUNT end')
    }

	handleClose() {
      this.setState({
        showModal: false,
        currentPhoto: ''
      });
    }

	handleClose2() {
      this.setState({
        showModal2: false,
        currentText: ''
      });
    }

	handleCloseObject() {
      this.setState({
        showModalObject: false,
        currentObject: ''
      });
    }

    handleCloseWebEntity() {
      this.setState({
        showModalWebEntity: false,
        currentWebEntity: ''
      });
    }

    handleRemove(id) {
	    this.props.onRemoveClick(id);
    } 

	render() {
		const photo = this.props.photo;
		const showDetectionDetails = this.props.showDetectionDetails;

		if (photo.similarImages) {
			this.dopples = photo.similarImages.map(similarPhoto => {

			  const styles = {
			    backgroundImage: "url(" + similarPhoto.url + ")",
			  }

			  return (
			    <div
			      onClick={() => this.setState({ showModal: true, currentPhoto: similarPhoto.url })}
			      style={styles}
			      className="main-photo card-1 card"
			      key={similarPhoto.url}
			      xs={4} >
			    </div>

			  );
			});
		} else {
			this.dopples =
			  <div className="scrolling-wrapper">
			    <div className="main-photo card-1 card loading-card" xs={4}>
			      <ScaleLoader
			        color={'#000'}
			        loading={true}
			      />
			    </div>
			    <div className="main-photo card-1 card loading-card" xs={4}>
			      <ScaleLoader
			        color={'#000'}
			        loading={true}
			      />
			    </div>
			    <div className="main-photo card-1 card loading-card" xs={4}>
			      <ScaleLoader
			        color={'#000'}
			        loading={true}
			      />
			    </div>
			    <div className="main-photo card-1 card loading-card" xs={4}>
			      <ScaleLoader
			        color={'#000'}
			        loading={true}
			      />
			    </div>
			  </div>
		}
		if (photo.detectedTexts) {
			//no pillamos el primer elemento oprque contiene todos los textos en un solo elemento
			[, ...this.detectedTexts] = photo.detectedTexts.map(detectedText => {

			  return (
			    <Col
			      className="detected-text card-1 card"
			      onClick={() => this.setState({ showModal2: true, currentText: detectedText, currentCanvasVertices: detectedText.boundingPoly.vertices, areNormalizedVertices: false })}
			      xs={3} md={4}>
			      {detectedText.description}
			    </Col>

			  );
			});
		} else {
			this.detectedTexts =
			  <div className="scrolling-wrapper">
			  </div>
		}
		if (photo.detectedObjects) {
			this.detectedObjects = photo.detectedObjects.map(detectedObject => {
			  return (
			    <Col
			      className="detected-object card-1 card"
			      onClick={() => this.setState({ showModalObject: true, currentObject: detectedObject, currentCanvasVertices: detectedObject.boundingPoly.normalizedVertices, areNormalizedVertices: true })}
			      xs={6}>
			      {detectedObject.name}
			    </Col>

			  );
			});
		} else {
			this.detectedObjects =
			  <div className="scrolling-wrapper">
			  </div>
		}			
		if (photo.webEntities) {
			this.detectedWebEntities = photo.webEntities.map(webEntity => {
			  return (
			    <Col
			      className="detected-web-entity card-1 card"
			      onClick={() => this.setState({ showModalWebEntity: true, currentWebEntity: webEntity })}
			      xs={4}>
			      {webEntity.description}
			    </Col>

			  );
			});
		} else {
			this.detectedWebEntities =
			  <div className="scrolling-wrapper">
			  </div>
		}				

        /*<Image style={{ width: '100%' }} src={photo.url} responsive="true" />*/

		return (
            <div key={photo.id}>
		                <div style={{minHeight: '215px'}}>
		                    <i onClick={() => this.handleRemove(photo.id)} className="bottom-icon material-icons main-close">close</i>
							<ImageCanvas url={photo.url} vertices={this.state.currentCanvasVertices} areNormalizedVertices={this.state.areNormalizedVertices} />
		                </div>
		                <div className="scrolling-wrapper">
		                  {this.detectedObjects}
		                </div>
		                <div className="scrolling-wrapper">
		                  {this.detectedTexts}
		                </div>
		                <div className="scrolling-wrapper">
		                  {this.detectedWebEntities}
		                </div>
		                <div className="scrolling-wrapper">
		                  {this.dopples}
		                </div>
		               <Modal show={this.state.showModal} onHide={this.handleClose}>
		                    <Modal.Header closeButton>
		                      <Modal.Title>Google said you look like...</Modal.Title>
		                    </Modal.Header>
		                    <Modal.Body>
		                      <Image style={{ width: '100%' }} src={this.state.currentPhoto} responsive="true" />

		                    </Modal.Body>
		                    <Modal.Footer>
		                      <div onClick={this.handleClose}>Close</div>
		                    </Modal.Footer>
		                </Modal>
		               <Modal show={showDetectionDetails && this.state.showModal2} onHide={this.handleClose2}>
		                    <Modal.Header closeButton>
		                      <Modal.Title>Texts info...</Modal.Title>
		                    </Modal.Header>
		                    <Modal.Body>
		                    	<div>
		                    		<p>description: {this.state.currentText.description}</p>
		                    		<p>confidence: {this.state.currentText.confidence}</p>
		                    		<p>score: {this.state.currentText.score}</p>
		                    		<p>topicality: {this.state.currentText.topicality}</p>
		                    		<p>locale: {this.state.currentText.locale}</p>
		                    		<p>object: {JSON.stringify(this.state.currentText)}</p>
		                    	</div>
		                    </Modal.Body>
		                    <Modal.Footer>
		                      <div onClick={this.handleClose2}>Close</div>
		                    </Modal.Footer>
		                </Modal>
		               <Modal show={showDetectionDetails && this.state.showModalObject} onHide={this.handleCloseObject} >
		                    <Modal.Header closeButton>
		                      <Modal.Title>Objects info...</Modal.Title>
		                    </Modal.Header>
		                    <Modal.Body>
		                    	<div>
		                    		<p>name: {this.state.currentObject.name}</p>
		                    		<p>object: {JSON.stringify(this.state.currentObject)}</p>
		                    	</div>
		                    </Modal.Body>
		                    <Modal.Footer>
		                      <div onClick={this.handleCloseObject}>Close</div>
		                    </Modal.Footer>
		                </Modal>
		               <Modal show={this.state.showModalWebEntity} onHide={this.handleCloseWebEntity}>
		                    <Modal.Header closeButton>
		                      <Modal.Title>Objects info...</Modal.Title>
		                    </Modal.Header>
		                    <Modal.Body>
		                    	<div>
		                    		<p>name: {this.state.currentWebEntity.name}</p>
		                    		<p>object: {JSON.stringify(this.state.currentWebEntity)}</p>
		                    	</div>
		                    </Modal.Body>
		                    <Modal.Footer>
		                      <div onClick={this.handleCloseWebEntity}>Close</div>
		                    </Modal.Footer>
		                </Modal>
            </div>
		);
    }
}

import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyA0XDtf2eH93ngqpsfX8XIKu3d04-zVDr4",
    authDomain: "doppelganger-7b34f.firebaseapp.com",
    databaseURL: "https://doppelganger-7b34f.firebaseio.com",
    projectId: "doppelganger-7b34f",
    storageBucket: "doppelganger-7b34f.appspot.com",
    messagingSenderId: "873819936099",
    appId: "1:873819936099:web:9fe9ff9182d212f84b2f56",
    measurementId: "G-RS4QSR3WKD"
};
firebase.initializeApp(config);

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firebaseAuth = firebase.auth;
export const db = firebase.firestore(); //.settings({ timestampsInSnapshots: true });

export const appTokenKey = 'appToken';
export const firebaseAuthKey = 'firebaseAuthInProgress';

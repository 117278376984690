import React, { Component } from 'react';
import { loginWithGoogle, logout } from '../helpers/auth';
import { firebaseAuth, appTokenKey, firebaseAuthKey } from '../config/constants';
//const firebaseAuthKey = 'firebaseAuthInProgress';
//const appTokenKey = 'appToken';

export default class Login extends Component {

constructor(props) {
        super(props);
        this.state = { splashScreen: false };
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    }

handleGoogleLogin() {
     loginWithGoogle()
     .catch(err => {
      localStorage.removeItem(firebaseAuthKey)
     });
// this will set the splashscreen until its overridden by the real firebaseAuthKey
     localStorage.setItem(firebaseAuthKey, '1');
    }

componentDidMount() {
// checks if we are logged in, if we are go to the home route
        if (localStorage.getItem(appTokenKey)) {
        	console.log("appTokenKey ok: reidirignedo a home");
        	alert("appTokenKey ok: reidirignedo a home");
            this.props.history.push('/app/home');
            return;
        }
		firebaseAuth().onAuthStateChanged(user => {
        	console.log("onAuthStateChanged");
        	alert("onAuthStateChanged");
			if (user) {
	        	console.log("onAuthStateChanged: user defined");
	        	alert("onAuthStateChanged: user defined");
				localStorage.removeItem(firebaseAuthKey);
				localStorage.setItem(appTokenKey, user.uid);
				this.props.history.push('/app/home')
			}else{
	        	console.log("onAuthStateChanged: user nil");
	        	alert("onAuthStateChanged: user nil");
				localStorage.removeItem(firebaseAuthKey);
		        logout()
		        .then(() => {
		            localStorage.removeItem(appTokenKey);
		            this.props.history.push("/login");
		            alert("user forced signed out from firebase");            
		            console.log("User forced signed out from firebase");            
		        });
			}
        })
    }

render() {
	if (localStorage.getItem(firebaseAuthKey) === '1') 
	   return <Splashscreen />;
	  return <LoginPage handleGoogleLogin={this.handleGoogleLogin} />;
	}
}


// this is the URL we copied from firebase storage
const loginButtonUrl = 'https://firebasestorage.googleapis.com/v0/b/doppelganger-7b34f.appspot.com/o/google-icon-white.png?alt=media&token=5e253ac6-3b1f-4209-9cd5-0ba9ca65725b';
const styles = {
 backgroundImage: `url(${loginButtonUrl})`
}

const LoginPage = ({ handleGoogleLogin }) => (
<div className="login-container">
  <div onClick={handleGoogleLogin} className="login-button">
   <div style={styles} className="google-logo">
    <span className="button-text">Sign In With Google</span>
   </div>
  </div>
 </div>
)
const Splashscreen = () => (<p>Please Wait Loading...</p>);
import React, { useRef, useEffect, useState } from "react";
import { Image , Stage, Layer, Line } from 'react-konva';
//import Konva from 'konva';

class ColoredLine extends React.Component {
/*  state = {
    color: 'green',
    canvasVertices: this.props.canvasVertices,
    scale: this.props.scaleCalculated
  };*/

  render() {
    const color = 'red';
    const canvasVertices = this.props.canvasVertices;
    const scaleX = this.props.dimensions.scale;
    const scaleY = this.props.dimensions.scale;
    const originalWidth = this.props.dimensions.originalWidth;
    const originalHeight = this.props.dimensions.originalHeight;

	console.log ("ColoredLine: scaleX: " + scaleX)
	
    var points = [];
	if (!canvasVertices) {
		points = [0,0,0,0];
	} else {
		if (this.props.areNormalizedVertices) {
			console.log("areNormalizedVertices");
		  	points = canvasVertices.map( vertice => [vertice.x*originalWidth, vertice.y*originalHeight]).flat(); //[23, 20, 23, 160, 70, 93, 150, 109, 290, 139, 270, 93]			
		} else {
		  	points = canvasVertices.map( vertice => [vertice.x, vertice.y]).flat(); //[23, 20, 23, 160, 70, 93, 150, 109, 290, 139, 270, 93]			
		}
	}

  	console.log ( "ColoredLine: points: " + points);

    return (

		<Line
			points={points}
			shadowBlur={5}
			stroke={color}
			strokeWidth={2/scaleX}
			scaleX={scaleX}
			scaleY={scaleY}
			closed  />
    );
  }
}


const ImageCanvas = props => {
  const targetRef = useRef(); //necesario para tener el elemento padre para obtener su ancho
  const imageUrl = props.url;
  const canvasVertices = props.vertices;
  const areNormalizedVertices = props.areNormalizedVertices;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, scale:1, originalHeight:0, originalWidth: 0 });

  console.log("ImageCanvas: areNormalizedVertices: " + areNormalizedVertices);

  // ref e imagen state que necesitamos para asociar la imagen precargada a la imagen del canvas. Esto hay que hacerlo precargando la imagen en un elemento html oculto para poder calcular la
  // escala a la que pintar la altura del canvas y la propia imagen para meterla en una anchura fija
  const [imageLoaded, setImageLoaded] = useState(false); //necesario tener un state para forzar recalculos al finalizar la carga de la imagen
  const imageRef = React.createRef();
  const [image, setImage] = useState(new window.Image());

    image.src = imageUrl;

    image.onload = function()
    {
    	console.log("newImage naturalWidth: " + image.naturalWidth + " imageLoaded: " + imageLoaded);

	    if (targetRef.current) {
	      if (image && !imageLoaded) {
	      	console.log ("AUIQ!!!: ");
	      	console.log( image.width);
	      	console.log( image.naturalWidth);

	      	// calculamos la scala a usar partiendo de ancho fijo definido por el elemento padre.
	      	const scaleCalculated = targetRef.current.offsetWidth / image.naturalWidth;
	      	console.log ("scale: " + scaleCalculated);
	      	setDimensions({
		        width: targetRef.current.offsetWidth,
		        height: image.naturalHeight * scaleCalculated,
		        scale: scaleCalculated,
		        originalWidth: image.naturalWidth,
		        originalHeight: image.naturalHeight
		    });
		    setImageLoaded(true);
	      }
		  console.log( "vertices: " + canvasVertices );
	    }
    }
  
  /*useEffect(() => {
    if (targetRef.current) {
      if (imageRef.current) {
      	console.log ("AUIQ!!!: ");
      	console.log( imageRef.current.width);
      	console.log( imageRef.current.naturalWidth);

      	// calculamos la scala a usar partiendo de ancho fijo definido por el elemento padre.
      	const scaleCalculated = targetRef.current.offsetWidth / imageRef.current.naturalWidth;
      	console.log ("scale: " + scaleCalculated);
      	setDimensions({
	        width: targetRef.current.offsetWidth,
	        height: imageRef.current.naturalHeight * scaleCalculated,
	        scale: scaleCalculated,
	        originalWidth: imageRef.current.naturalWidth,
	        originalHeight: imageRef.current.naturalHeight
	    });
	    // asignamos a la 
      	setImage(imageRef.current);
      }

      console.log( "vertices: " + canvasVertices );
    }
  }, []);*/

  function handleImageOnLoad( ) {
	console.log('handleImageOnLoad: init');
	/*setImageLoaded(true);*/
  }
  function handleImageOnLoadedData( ) {
	console.log('handleImageOnLoadedData: init');
	/*setImageLoaded(true);*/
  }

  return (
    <div className="responsive-canvas2" ref={targetRef}>		            
		
  		<Stage width={dimensions.width} height={dimensions.height}>
			<Layer>
				<Image image={image} scaleX={dimensions.scale} scaleY={dimensions.scale} />
				<ColoredLine canvasVertices={canvasVertices} dimensions={dimensions} areNormalizedVertices={areNormalizedVertices} />
			</Layer>
		</Stage>
	</div>
  );

  function leches(){
  	return (
  	<img
	        style={/*imageLoaded ? {} : */{ display: 'none' }}
	        ref={imageRef}
	        src={imageUrl}
	        /*onLoad={ () => setImageLoaded(true)}*/
	        onLoad={handleImageOnLoad()}
	        onloadeddata={handleImageOnLoadedData()} />
  )};
};

export default ImageCanvas;

